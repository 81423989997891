import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useIsMobile } from '../../hooks';

function StepCard({ stepNumber, text }) {
  const isMobile = useIsMobile();
  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={2}
      maxWidth={260}
      sx={{
        margin: '0 auto',
        backgroundColor: 'white',
        borderRadius: 5,
        width: '90%',
        height: isMobile ? '175px' : '155px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        '&:hover': {
          boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.5)',
          transform: 'scale(1.05)',
        },
        transition: 'all 0.15s ease-in-out',
      }}
    >
      <Box
        sx={{
          mt: 3,
          backgroundColor: 'text.primary',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h2"
          color="common.white"
        >
          {stepNumber}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        align="center"
        px={1.5}
        sx={{
          fontSize: { xs: '0.7rem', md: '0.8rem' },
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
}

StepCard.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default StepCard;
